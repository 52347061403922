import './style.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createVfm } from 'vue-final-modal'
import 'vue-final-modal/style.css'
import * as Sentry from "@sentry/vue";
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'


import App from '@/App.vue'
import router from '@/router'

const pinia = createPinia()
const vfm = createVfm()
const app = createApp(App)


Sentry.init({
  environment: import.meta.env.MODE ?? 'production',
  app,
  dsn: "https://82451143717b4dd454fb1d371e7fc39a@o4507712595820545.ingest.de.sentry.io/4507712854491216",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  //tracesSampleRate: 1.0, //  Capture 100% of the transactions
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api.testpsicotecnico\.es\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


app.use(pinia)
app.use(router)
app.use(vfm)
app.use(autoAnimatePlugin)

app.mount('#app')


// Reload the page when a preload error occurs
window.addEventListener('vite:preloadError', () => {
  window.location.reload() // for example, refresh the page
})
