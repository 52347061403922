import axios from "axios";
import interceptor from "@/services/axiosInterceptors";

const clienteApi = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  },
  withCredentials: false,
});

interceptor(clienteApi);

export default clienteApi;