import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import clienteApi from '@/services/clienteApi'
import { useErrorStore } from '@stores/ErrorStore'
import { authServerError } from '@/helpers/AuthMessages'

export const useUserStore = defineStore('user', () => {
  
  
  const errorStore = useErrorStore()
  const router = useRouter()


  //#region // * User

  const user = ref()
  const getUser = computed(() => user.value)
  const setUser = (newUser) => {
    user.value = newUser
    setAdmin()
  }

  //#endregion

  //#region // * Actualizar Usuario

  async function update(datos) {
    try {
      await clienteApi.patch('/api/user', datos, {
        headers: {
          //!Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
        }
      })
      router.push({ name: 'perfilshow' })
    } catch (error) {
      authServerError(error)
    }
  }

  //#endregion

  //#region // * Fetch Usuario

  const fetchUser = () =>
    clienteApi('/api/user', {
      headers: {
        //!Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
      }
    })
      .then((data) => {
        setUser(data.data)
        setAutenticado(true)
      })
      .catch((error) => {
        if (error.code === 'ERR_NETWORK') {
          errorStore.setErrors('auth', 'Error de conexión')
        }
        if (error?.response?.data?.message) {
          setAutenticado(false)
          errorStore.setErrors('auth', error.response.data.message)
        }
      })

    //#endregion

  //#region // * Eliminar Imagen Antigua

  async function deleteOldImg() {
    try {
      await clienteApi.delete('/api/user/image')
    } catch (error) {
      console.log(error)
    }
  }

  //#endregion

  //#region // * Eliminación de cuenta

  async function deleteAccount() {
    try {
      await clienteApi.delete('/api/user/delete', {
        headers: {
          //!Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
        }
      })
      localStorage.removeItem('AUTH_TOKEN')
      router.push({ name: 'login' })
    } catch (error) {
      authServerError(error)
    }
  }

  //#endregion

  //#region // * Admin

  const admin = ref('usuario')

  const getAdmin = computed(() => admin.value)
  const setAdmin = () => {
      admin.value = user.value.rol.nombre
  }

  //#endregion

  //#region // * Autenticado

  const autenticado = ref(false)
  const getAutenticado = computed(() => autenticado.value)
  const setAutenticado = (value) => {
    autenticado.value = value
  }
  
  return {
    user,
    getUser,
    setUser,
    getAdmin,
    fetchUser,
    update,

    deleteOldImg,
    deleteAccount,

    getAutenticado,
    setAutenticado
  }
})
