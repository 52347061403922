export default [{
  path: '/auth',
  redirect: '/auth/login',
  name: 'GuestLayout',
  component: () => import('@/layouts/GuestLayout.vue'),
  meta: { requiresAuth: false },
  children: [
    {
      path: '/auth/login',
      meta: { refreshUser: true},
      name: 'login',
      component: () => import('@/views/auth/LoginView.vue')
    },
    {
      path: '/auth/register',
      name: 'register',
      component: () => import('@/views/auth/RegisterView.vue')
    },
    {
      path: '/auth/confirmation-send',
      name: 'confirmationsend',
      component: () => import('@/views/auth/ConfirmationSendView.vue')
    },
    {
      path: '/auth/confirmation-resend',
      name: 'confirmationresend',
      component: () => import('@/views/auth/ConfirmationReSendView.vue')
    },
    {
      path: '/auth/forgot-password',
      name: 'forgotpassword',
      component: () => import('@/views/auth/ForgotPasswordView.vue')
    },
    {
      path: '/auth/reset-password',
      name: 'resetpassword',
      component: () => import('@/views/auth/ResetPasswordView.vue')
    }
  ]
}]