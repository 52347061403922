import axios from 'axios'
import get from 'lodash/get'
//import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/UserStore'
import getToken from '@/helpers/getToken'



const interceptor = instance =>{
  instance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {

    const status = get(error, 'response.status')
    if (status === 419) {
      // Refresh our session token
      axios.get('/csrf-token')
  
      // Return a new request using the original request's configuration
      return axios(error.response.config)
    }
    if (status === 401) {
      //const router = useRouter()
      const userStore = useUserStore()

      userStore.setAutenticado(false)
      //router.push({ name: 'login' })
    }
  
    return Promise.reject(error)
  })
  instance.interceptors.request.use((config) => {
    
    let tokenString = getToken();

    config.headers.Authorization = tokenString;
    return config;
  }, (error) => {
    return Promise.reject(error);
  });
}

export default interceptor;
