import { defineStore } from "pinia";
import { ref, computed } from 'vue';

export const useErrorStore = defineStore('error', () => {

 // ERRORS

 const updated = ref(0);
 const getUpdated = computed(() => updated.value);

 const errors = ref({});

const getErrors = computed(() => errors.value);

const setErrors = (key, newErrors) => {
  updated.value = Math.floor(Math.random() * 10000);
  errors.value[key] = [...(errors.value[key] || []), newErrors];
}

const cleanTypeErrors = (key) => {
  errors.value[key] = [];
}

const cleanAllErrors = () => {
  updated.value = Math.floor(Math.random() * 10000);
  errors.value = {};
}

  return{
        // ERRORS
        errors, 
        getErrors, 
        cleanTypeErrors, 
        cleanAllErrors, 
        setErrors,
        getUpdated
  }
})