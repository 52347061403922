import { defineStore } from "pinia";
import { ref, computed } from 'vue';

export const useSuccessStore = defineStore('success', () => {

 // success

 const updated = ref(false);

 const success = ref({
  email : [],
  password : [],
  password_confirmation : [],
  auth : [],
});

const getSuccess = computed(() => success.value);

const setSuccess = (key, newsuccess) => {
  updated.value = false
  success.value[key] = [...(success.value[key] || []), newsuccess];
}

const cleanTypeSuccess = (key) => {
  success.value[key] = [];
}

const cleanAllSuccess = () => {
  success.value = {
    email : [],
    password : [],
    password_confirmation : [],
    auth : [],
  };
}

  return{
        // success
        success, 
        getSuccess, 
        cleanTypeSuccess, 
        cleanAllSuccess, 
        setSuccess,
  }
})