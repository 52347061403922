export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/layouts/AcademyLayout.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '/dashboard/principal',
        name: 'principal',
        component: () => import('@/views/dashboard/InicioView.vue')
      },
      {
        path: '/dasboard/perfil',
        name: 'perfil',
        redirect: { name: 'perfilshow' },
        component: () => import('@/views/dashboard/ProfileView.vue'),
        meta: { refreshUser: true},
        children: [
          {
            path: '',
            name: 'perfilshow',
            component: () => import('@/views/dashboard/profile/ProfileShow.vue')
          },
          {
            path: '/dashboard/perfil/editar',
            name: 'editarperfil',
            component: () => import('@/views/dashboard/profile/ProfileEdit.vue')
          },
          {
            path: '/dashboard/perfil/eliminar',
            name: 'eliminarperfil',
            component: () => import('@/views/dashboard/profile/ProfileDelete.vue')
          },
          {
            path: '/dashboard/perfil/imagen',
            name: 'imagenperfil',
            component: () => import('@/views/dashboard/profile/ProfileEditImg.vue')
          },

        ],
      },
      {
        path: '/dashboard/perfil/subscripcion',
        name: 'subscripcion',
        component: () => import('@/views/dashboard/SubscriptionView.vue')
      },
      //#section //* Tests
      { 
        path: '/dashboard/tests',
        name: 'tests',
        component: () => import('@/views/dashboard/TestsView.vue'),
      },
      {
        path: '/dashboard/tests/omnibus',
        name: 'omnibus',
        meta: { refreshUser: true},
        component: () => import('@/views/dashboard/tests/OmnibusView.vue')
      },
      {
        path: '/dashboard/tests/especificos',
        name: 'especificos',
        component: () => import('@/views/dashboard/EspecificosView.vue')
      },
      {
        path: '/dashboard/tests/especificos/administrativo',
        name: 'administrativo',
        meta: { refreshUser: true},
        component: () => import('@/views/dashboard/tests/AdministrativoView.vue')
      },
      {
        path: '/dashboard/tests/especificos/calculo',
        name: 'calculo',
        meta: { refreshUser: true},
        component: () => import('@/views/dashboard/tests/CalculoView.vue')
      },
      {
        path: '/dashboard/tests/especificos/codificacion',
        name: 'codificacion',
        meta: { refreshUser: true},
        component: () => import('@/views/dashboard/tests/CodificacionView.vue')
      },
      {
        path: '/dashboard/tests/especificos/comprensionlectora',
        name: 'comprensionlectora',
        meta: { refreshUser: true},
        component: () => import('@/views/dashboard/tests/ComprensionLectoraView.vue')
      },
      {
        path: '/dashboard/tests/especificos/fatigavisual',
        name: 'fatigaVisual',
        meta: { refreshUser: true},
        component: () => import('@/views/dashboard/tests/FatigaVisualView.vue')
      },
      {
        path: '/dashboard/tests/especificos/germinativo',
        name: 'germinativo',
        meta: { refreshUser: true},
        component: () => import('@/views/dashboard/tests/GerminativoView.vue')
      },
      {
        path: '/dashboard/tests/especificos/resistenciafatiga',
        name: 'resistenciafatiga',
        meta: { refreshUser: true},
        component: () => import('@/views/dashboard/tests/ResistenciaFatigaView.vue')
      },
      {
        path: '/dashboard/tests/especificos/verbal',
        name: 'verbal',
        meta: { refreshUser: true},
        component: () => import('@/views/dashboard/tests/VerbalView.vue')
      },
      {
        path:'/dashboard/estadistica',
        name: 'estadistica',
        meta: { refreshUser: true},
        component: () => import('@/views/dashboard/StatisticsView.vue')
      },
      {
        path: '/dashboard/teoria',
        name: 'teoria',
        meta: { refreshUser: true},
        component: () => import('@/views/dashboard/TeoriaView.vue')
      }
    ]
  }
]