
/**
 * Este helper se encarga de obtener el token de autenticación
 * de la sesión actual, ya sea del localStorage o del sessionStorage
 * y lo retorna en un string con el formato 'Bearer token'
 *
 * @returns {string} tokenString
*/
const getToken = () => {

  let tokenString;
    if(localStorage.getItem('AUTH_TOKEN')) {
      tokenString = `Bearer ${localStorage.getItem('AUTH_TOKEN')}`;
    } else {
      tokenString = `Bearer ${sessionStorage.getItem('SESSION_TOKEN')}`;
    }

  return tokenString;
}

export default getToken;