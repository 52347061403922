import { useErrorStore } from "@/stores/ErrorStore";
import { useSuccessStore } from "@/stores/SuccessStore";



const authServerError = (error) => {
  const errorStore = useErrorStore();
  if(error?.response?.status === 500){
    errorStore.setErrors('auth', 'Server Error');
    return;
  }

  if(error?.response?.status === 403){
    errorStore.setErrors('auth', 'No autorizado');
    return;
  }

  if(error?.status === 'error'){
    errorStore.setErrors('auth', error.message);
    return;
  }
  typeof(error?.response?.data?.errors) === 'string' 
  ? errorStore.setErrors('email', error.response.data.errors) 
  : Object.keys(error?.response?.data?.errors ?? {}).forEach((e)=> {
    error?.response?.data?.errors[e].forEach(d=>{
        errorStore.setErrors(e, d);
      })
    });    
}



const authServerSuccess = (data) => {
  const successStore = useSuccessStore();
  if(data?.status === 'success'){
    successStore.setSuccess('auth', data.message);
    return;
  }
  
  typeof(data?.response?.data?.errors) === 'string' 
  ? successStore.setSuccess('email', data.response.data.errors) 
  : Object.keys(data?.response?.data?.errors).forEach((e)=> {
      data?.response?.data?.errors[e].forEach(d=>{
        successStore.setSuccess(e, d);
      })
    });    
}



export{
  authServerError,
  authServerSuccess
}


