import { createRouter, createWebHistory } from 'vue-router'
import clienteApi from '@/services/clienteApi'
import { useErrorStore } from '@/stores/ErrorStore'
import { useUserStore } from '@/stores/UserStore'
import { translateError } from '@/helpers/traducciones'
import NProgress from 'nprogress';
import authRoutes from '@/router/authRoutes'
import adminRoutes from '@/router/adminRoutes'
import dashboardRoutes from '@/router/dashboardRoutes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/terminos-y-condiciones',
      name: 'terminos',
      component: () => import('@/views/legal/CondicionesView.vue')
    },
    {
      path: '/',
      redirect: '/auth/login'
    },
    ...authRoutes,
    ...dashboardRoutes,
    ...adminRoutes,
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: () => import('@/views/NotFound.vue')
    } // Route for handling not found pages
  ]
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

router.beforeEach(async (to) => {

  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
}

  const errorStore = useErrorStore()
  const userStore = useUserStore()
  
  let noVerificado = false

  const fetcher = () => 
    clienteApi('/api/user', {
      headers: {}
    })
      .then((data) => {
        userStore.setAutenticado(true)
        userStore.setUser(data.data)
      })
      .catch((error) => {
        if (error.code === 'ERR_NETWORK') {
          errorStore.setErrors('auth', 'Error de conexión')
        }

        if (error?.response?.data?.message) {
          if(error.response.data.message === 'Unauthenticated.'){
            return
          }
          errorStore.setErrors('auth', translateError(error.response.data.message))
          userStore.setAutenticado(false)

          if (error.response.data.message === 'Your email address is not verified.') {
            noVerificado = true
          }
        }
      })

  if(userStore.getAutenticado === false && to.name !== 'register') {
    NProgress.set(0.4);
    await fetcher()
  }

  if (to.meta.refreshUser === true ) {
    NProgress.set(0.4);
    await fetcher()
  }

  if (noVerificado) {
    setTimeout(() => {
      return { name: 'confirmationresend' }
    }, 5000)
  }

  if (userStore.getAutenticado === true && to.meta.requiresAuth === false) {
    return { name: 'principal' }
  }

  if (to.meta.requiresAuth) {
    if (to.meta.requiresAdmin && userStore.getAdmin === 'user') {
      return { name: 'principal' }
    }
    if (noVerificado) {
      return { name: 'confirmationresend' }
    }

    if (to.meta.requiresAuth && userStore.getAutenticado === false) {
      return { name: 'login' }
    }
  }
  
})




// solucionc
// https://stackoverflow.com/questions/69300341/typeerror-failed-to-fetch-dynamically-imported-module-on-vue-vite-vanilla-set
router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  ) {
    if (!to?.fullPath) {
      window.location.reload();
    } else {
      window.location = to.fullPath;
    }
  }
})


export default router
