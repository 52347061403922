export default [
  {
    path: '/administracion',
    name: 'admin',
    redirect: '/administracion/index',
    component: () => import('@/layouts/AdminLayout.vue'),
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      {
        path: '/administracion/subida-imagenes',
        name: 'adminSubidaImagenes',
        component: () => import('@/views/admin/AdminSubidaImagenesView.vue'),
        meta: { requiresAuth: true, requiresAdmin: true }
      },
      {
        path: '/administracion/index',
        name: 'adminIndex',
        component: () => import('@/views/admin/AdminIndexView.vue')
      },
      {
        path: '/administracion/usuarios',
        name: 'adminUsuarios'
      },
      {
        path: '/administracion/temario',
        name: 'adminTemario',
        component: () => import('@/views/admin/temario/AdminTemarioView.vue')
      },
      {
        path: '/administracion/temario/crear',
        name: 'adminTemarioCrear',
        meta: { refreshUser: true },
        component: () => import('@/views/admin/temario/AdminTemarioCrearView.vue')
      },
      {
        path: '/administracion/reportes',
        name: 'adminReportes',
        meta: { refreshUser: true, requiresAdmin: true },
        component: () => import('@/views/admin/AdminReportesView.vue')
      },
      {
        path: '/administracion/academia',
        name: 'adminAcademia',
        component: () => import('@/views/admin/academia/AdminAcademiaView.vue')
      },
      {
        path: '/administracion/academia/importar-especificos',
        name: 'adminAcademiaImportarEspecificos',
        component: () => import('@/views/admin/academia/AdminAcademiaExamenEspecificosView.vue'),
        meta: { refreshUser: true, requiresAdmin: true },
      },
      {
        path: '/administracion/omnibus',
        name: 'adminOmni',
        meta: { refreshUser: true },
        children: [
          {
            path: '/administracion/omnibus/importar',
            name: 'adminOmniImportar',
            component: () => import('@/views/admin/academia/omnibus/AdminOmnibusExcelView.vue')
          },
          {
            path: '/administracion/omnibus/preguntas',
            name: 'adminOmniPreguntas',
            component: () => import('@/views/admin/academia/omnibus/AdminOmnibusPreguntasView.vue')
          },
          {
            path: '/administracion/omnibus/preguntas/crear',
            name: 'adminOmniPreguntasCrear',
            component: () =>
              import('@/views/admin/academia/omnibus/AdminOmnibusPreguntasCrearView.vue')
          },
          {
            path: '/administracion/omnibus/examenes',
            name: 'adminOmniExamenes',
            component: () => import('@/views/admin/academia/omnibus/AdminOmnibusExamenesView.vue')
          },
          {
            path: '/administracion/omnibus/examenes/crear',
            name: 'adminOmniExamenesCrear',
            component: () =>
              import('@/views/admin/academia/omnibus/AdminOmnibusExamenesCrearView.vue')
          }
        ]
      },
      {
        path: '/administracion/germinativos',
        name: 'adminGermi',
        meta: { refreshUser: true },
        children: [
          {
            path: '/administracion/germinativos/examenes',
            name: 'adminGermiExamenes',
            component: () =>
              import('@/views/admin/academia/germinativos/AdminGerminativosExamenesView.vue')
          },
          {
            path: '/administracion/germinativos/examenes/crear',
            name: 'adminGermiExamenesCrear',
            component: () =>
              import('@/views/admin/academia/germinativos/AdminGerminativosExamenesCrearView.vue')
          }
        ]
      },
      {
        path: '/administracion/fatigavisual',
        name: 'adminFatigaVisual',
        meta: { refreshUser: true },
        children: [
          {
            path: '/administracion/fatigavisual/examenes',
            name: 'adminFatigaVisualExamenes',
            component: () =>
              import('@/views/admin/academia/fatigavisual/AdminFatigaVisualExamenesView.vue')
          },
          {
            path: '/administracion/fatigavisual/examenes/crear',
            name: 'adminFatigaVisualExamenesCrear',
            component: () =>
              import('@/views/admin/academia/fatigavisual/AdminFatigaVisualExamenesCrearView.vue')
          }
        ]
      },
      {
        path: '/administracion/administrativo',
        name: 'adminAdministrativo',
        meta: { refreshUser: true },
        children: [
          {
            path: '/administracion/administrativo/examenes',
            name: 'adminAdministrativoExamenes',
            component: () =>
              import('@/views/admin/academia/administrativo/AdminAdministrativoExamenesView.vue')
          },
          {
            path: '/administracion/administrativo/examenes/crear',
            name: 'adminAdministrativoExamenesCrear',
            component: () =>
              import(
                '@/views/admin/academia/administrativo/AdminAdministrativoExamenesCrearView.vue'
              )
          }
        ]
      },
      {
        path: '/administracion/codificacion',
        name: 'adminCodificacion',
        meta: { refreshUser: true },
        children: [
          {
            path: '/administracion/codificacion/examenes',
            name: 'adminCodificacionExamenes',
            component: () => import('@/views/admin/academia/codificacion/AdminCodificacionExamenesView.vue')
          },
          {
            path: '/administracion/codificacion/examenes/crear',
            name: 'adminCodificacionExamenesCrear',
            component: () =>
              import('@/views/admin/academia/codificacion/AdminCodificacionExamenesCrearView.vue')
          }
        ]
      },
      {
        path: '/administracion/calculo',
        name: 'adminCalculo',
        meta: { refreshUser: true },
        children: [
          {
            path: '/administracion/calculo/examenes',
            name: 'adminCalculoExamenes',
            component: () => import('@/views/admin/academia/calculo/AdminCalculoExamenesView.vue')
          },
          {
            path: '/administracion/calculo/examenes/crear',
            name: 'adminCalculoExamenesCrear',
            component: () =>
              import('@/views/admin/academia/calculo/AdminCalculoExamenesCrearView.vue')
          }
        ]
      },
      {
        path: '/administracion/comprensionlectora',
        name: 'adminComprensionLectora',
        meta: { refreshUser: true },
        children: [
          {
            path: '/administracion/comprensionlectora/examenes',
            name: 'adminComprensionLectoraExamenes',
            component: () => import('@/views/admin/academia/comprensionlectora/AdminComprensionLectoraExamenesView.vue')
          },
          {
            path: '/administracion/comprensionlectora/examenes/crear',
            name: 'adminComprensionLectoraExamenesCrear',
            component: () =>
              import('@/views/admin/academia/comprensionlectora/AdminComprensionLectoraExamenesCrearView.vue')
          }
        ]
      },
      {
        path: '/administracion/resistenciafatiga',
        name: 'adminResistenciaFatiga',
        meta: { refreshUser: true },
        children: [
          {
            path: '/administracion/resistenciafatiga/examenes',
            name: 'adminResistenciaFatigaExamenes',
            component: () => import('@/views/admin/academia/resistenciafatiga/AdminResistenciaFatigaExamenesView.vue')
          },
          {
            path: '/administracion/resistenciafatiga/examenes/crear',
            name: 'adminResistenciaFatigaExamenesCrear',
            component: () =>
              import('@/views/admin/academia/resistenciafatiga/AdminResistenciaFatigaExamenesCrearView.vue')
          }
        ]
      },
      {
        path: '/administracion/verbal',
        name: 'adminVerbal',
        meta: { refreshUser: true },
        children: [
          {
            path: '/administracion/verbal/examenes',
            name: 'adminVerbalExamenes',
            component: () => import('@/views/admin/academia/verbal/AdminVerbalExamenesView.vue')
          },
          {
            path: '/administracion/verbal/examenes/crear',
            name: 'adminVerbalExamenesCrear',
            component: () =>
              import('@/views/admin/academia/verbal/AdminVerbalExamenesCrearView.vue')
          }
        ]
      },
    ]
  }
]
